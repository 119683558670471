import { Dispatch, FC, SetStateAction } from 'react'

import { Badge } from '@mui/material'

import { LoaderIcon } from '@redwoodjs/web/toast'

import { AuthClient } from 'src/lib/authClients'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import useReadLocalStorage from 'src/lib/hooks/UseReadLocalStorage'
import { isDebug } from 'src/Util'

import Button from '../Library/Button/Button'
import MicrosoftLogo from '../MicrosoftLogo/MicrosoftLogo'

interface SSOButtonProps {
  identityProviderLoading: boolean
  identityProviderLookup: (lookUpInput: {
    email: string
    redirect: boolean
  }) => Promise<AuthClient>
  storedAuthClients: AuthClient[]
  setStoredAuthClients: Dispatch<SetStateAction<AuthClient[]>>
}

export const SSOLoginButton: FC<SSOButtonProps> = ({
  identityProviderLoading,
  identityProviderLookup,
}) => {
  const { trackEvent } = useAnalytics()
  const ssoAuthClient =
    useReadLocalStorage<AuthClient>('lastLookupSSOClient') || null

  return (
    ssoAuthClient && (
      <div className="flex w-full flex-col place-items-center justify-center text-xs">
        <Badge
          color="warning"
          badgeContent={
            ssoAuthClient.identityProvider && isDebug
              ? ssoAuthClient.identityProvider
              : 0
          }
          className="w-full"
        >
          <Button
            onClick={() => {
              trackEvent('Pages', 'click login with microsoft')

              if (identityProviderLoading) {
                return
              }

              identityProviderLookup({
                email: ssoAuthClient.email,
                redirect: true,
              })
            }}
            startIcon={<MicrosoftLogo />}
            disabled={identityProviderLoading}
            variant="outlined"
            className="py-4"
          >
            {identityProviderLoading ? (
              <LoaderIcon />
            ) : (
              <span>Continue with Microsoft</span>
            )}
          </Button>
        </Badge>
      </div>
    )
  )
}
