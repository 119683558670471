import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { XCircleIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { TextField } from '@mui/material'

import { FormError } from './LoginForm'
import { NewPasswordValidation, validateNewPassword } from './LoginFormUtils'

interface NewPasswordFieldProps {
  onChange?: (value: string) => void
  value?: string
  isSubmitting?: boolean
  errorState?: FormError
  setErrorState?: Dispatch<SetStateAction<FormError>>
}

interface ValidationRequirementProps {
  isValidated: boolean
  title: string
}

const ValidationRequirement: FC<ValidationRequirementProps> = ({
  isValidated,
  title,
}) => {
  return (
    <li
      className={`flex items-center gap-2 justify-start ${
        isValidated ? 'text-green-600' : 'text-gray-500'
      }`}
    >
      {isValidated ? (
        <CheckCircleIcon className="w-5 h-5" />
      ) : (
        <XCircleIcon className="w-5 h-5" />
      )}
      {title}
    </li>
  )
}

const NewPasswordField: FC<NewPasswordFieldProps> = ({
  onChange,
  value,
  isSubmitting,
  errorState,
  setErrorState,
}) => {
  const initialValidationState: NewPasswordValidation = {
    minimumLength: false,
    includesLowercaseChar: false,
    includesUppercaseChar: false,
    includesSpecialChar: false,
    includesNumber: false,
  }

  const [newPasswordValidation, setNewPasswordValidation] =
    useState<NewPasswordValidation>(initialValidationState)

  useEffect(() => {
    if (
      newPasswordValidation.minimumLength &&
      newPasswordValidation.includesLowercaseChar &&
      newPasswordValidation.includesNumber &&
      newPasswordValidation.includesSpecialChar &&
      newPasswordValidation.includesUppercaseChar
    ) {
      setErrorState({ error: false, message: [] })
    }
  }, [newPasswordValidation])

  return (
    <div className="pb-2">
      <TextField
        id="newPassword"
        name="newPassword"
        label="New Password"
        type="password"
        value={value}
        onChange={(e) => {
          const value = e.target.value
          setNewPasswordValidation(validateNewPassword(value))
          onChange(value)
        }}
        fullWidth
        error={errorState.error}
        helperText={errorState?.message[0]}
        disabled={isSubmitting}
      />
      <p className="pt-6">Your password must include:</p>
      <ul className="text-sm flex gap-2 flex-col p-2">
        <ValidationRequirement
          isValidated={newPasswordValidation.minimumLength}
          title="At least eight characters"
        />
        <ValidationRequirement
          isValidated={newPasswordValidation.includesNumber}
          title="At least one number"
        />
        <ValidationRequirement
          isValidated={newPasswordValidation.includesSpecialChar}
          title={'At least one special character'}
        />
        <ValidationRequirement
          isValidated={newPasswordValidation.includesLowercaseChar}
          title="At least one lowercase letter"
        />
        <ValidationRequirement
          isValidated={newPasswordValidation.includesUppercaseChar}
          title="At least one uppercase character"
        />
      </ul>
    </div>
  )
}

export default NewPasswordField
