import { FC } from 'react'

import { TextField } from '@mui/material'

import FormErrorMessage from './FormErrorMessage'
import { FormError } from './LoginForm'

interface TOTPProps {
  value: string
  onChange: (value: string) => void
  errorState?: FormError
}

export const TOTP: FC<TOTPProps> = ({ value, onChange, errorState }) => {
  return (
    <>
      <p className="text-sm text-center text-gray-600 mt-4">
        Please enter your 2FA code from your authenticator app
      </p>

      <TextField
        id="totpField"
        name="totpField"
        inputProps={{
          'data-testid': 'totp-field',
        }}
        type="text"
        fullWidth
        label="2FA Code"
        value={value}
        onChange={(e) => {
          const value = e.target.value
          onChange(value)
        }}
        error={errorState?.error}
        helperText={<FormErrorMessage messages={errorState.message} />}
      />
    </>
  )
}
