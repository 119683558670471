import { useState } from 'react'

import {
  refreshUserAuthDetails,
  refreshUserAuthDetailsVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

const MUTATION = gql`
  mutation refreshUserAuthDetails($timezone: String) {
    refreshUserAuthDetails(timezone: $timezone)
  }
`

const useRefreshUserAuthDetails = () => {
  const [refreshing, setRefreshing] = useState(false)

  const [sync] = useMutation<
    refreshUserAuthDetails,
    refreshUserAuthDetailsVariables
  >(MUTATION)

  const refreshUserAuthDetails = async () => {
    setRefreshing(true)

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const result = await sync({
      variables: { timezone },
    }).finally(() => {
      setRefreshing(false)
    })

    return result
  }

  return {
    refreshing,
    refreshUserAuthDetails,
  }
}

export default useRefreshUserAuthDetails
