import { FC, useEffect, useState } from 'react'

import { TextField } from '@mui/material'
import { setUser } from '@sentry/browser'

import { navigate, routes } from '@redwoodjs/router'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import { SubmitData } from 'src/pages/LoginPage/LoginPage'

import Button from '../Library/Button/Button'

import FormErrorMessage from './FormErrorMessage'
import {
  validateEmailAddress,
  validatePassword,
  validateTOTPInput,
} from './LoginFormUtils'
import NewPasswordField from './NewPasswordField'
import { TOTP } from './TOTP'

export interface FormError {
  error: boolean
  message: string[]
}

interface LoginFormProps {
  loginToTheHub: (data: SubmitData) => Promise<void>
  loginFormSubmitError?: string
  newPasswordSubmitError?: string
  defaultEmail?: string
  resetAuthClient: () => void
  passwordChange: (password: string) => void
  totp: (totp: string) => void
  totpError?: string
  debouncedIdentityProviderLookup?: (value: string) => void
}

const LoginForm: FC<LoginFormProps> = ({
  loginToTheHub,
  loginFormSubmitError,
  newPasswordSubmitError,
  defaultEmail,
  resetAuthClient,
  passwordChange,
  totp,
  totpError,
  debouncedIdentityProviderLookup,
}) => {
  const { trackEvent } = useAnalytics()

  // Form States (Controls what fields show)
  const [isNewPassword, setIsNewPassword] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isTotp, setIsTotp] = useState<boolean>(false)

  // Form Field States
  const [email, setEmail] = useState<string>(defaultEmail)
  const [password, setPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [totpValue, setTotpValue] = useState<string>('')

  // Form Field Errors
  const [emailError, setEmailError] = useState<FormError>({
    error: false,
    message: [],
  })
  const [passwordError, setPasswordError] = useState<FormError>({
    error: false,
    message: [],
  })
  const [newPasswordError, setNewPasswordError] = useState<FormError>({
    error: false,
    message: [],
  })
  const [newTotpError, setNewTotpError] = useState<FormError>({
    error: false,
    message: [],
  })

  // Reset all errors
  const resetInputErrorCodes = () => {
    setEmailError({ error: false, message: [] })
    setPasswordError({ error: false, message: [] })
    setNewPasswordError({ error: false, message: [] })
    setNewTotpError({ error: false, message: [] })
  }

  // Validate inputs before sending to cognito
  const validateLoginFields = (inputs: {
    email: string
    password: string
    totpValue?: string
  }) => {
    // Default state
    const validatedFields = {
      emailIsValid: true,
      isValid: true,
    }

    /// Validate Email
    const emailValidation = validateEmailAddress(inputs.email)

    if (!emailValidation.valid) {
      validatedFields.emailIsValid = false
      validatedFields.isValid = false
      setEmailError({
        error: true,
        message: emailValidation.message,
      })
    }

    /// Validate Password
    const passwordValidation = validatePassword(inputs.password)

    if (!passwordValidation.valid) {
      validatedFields.isValid = false
      setPasswordError({
        error: true,
        message: passwordValidation.message,
      })
    }

    /// Validate TOTP
    if (isTotp) {
      const newTOTPValidation = validateTOTPInput(inputs.totpValue)
      if (!newTOTPValidation.valid) {
        validatedFields.isValid = false
        setNewTotpError({
          error: true,
          message: newTOTPValidation.message,
        })
      }
    }

    return validatedFields
  }

  const handleLoginSubmit = async (e) => {
    // Prevent the page resubmitting/reloading
    e.preventDefault()

    // Loading state
    setIsSubmitting(true)

    // Clear any existing error codes
    resetInputErrorCodes()

    /// VALIDATE INPUTS
    // NewPassword is already validated on input change (For live feedback on password requirements), so we don't need to check it here.
    const allCheckedFields = validateLoginFields({
      email,
      password,
      totpValue,
    })

    if (allCheckedFields.emailIsValid) {
      // Sentry: Set the user as soon as possible, so we can start logging/tracking progress
      setUser({ email })
    }

    if (allCheckedFields.isValid) {
      // Prep the data for login
      const loginInput: SubmitData = {
        email,
        password,
      }

      if (isNewPassword) {
        loginInput.newPassword = newPassword
      }

      if (totp) {
        loginInput.totpField = totpValue
      }

      // LOG THEM IN!
      await loginToTheHub(loginInput)
    } else {
      // Not Valid
      setIsSubmitting(false)
    }
  }

  // NEW EMAIL/PASSWORD ERROR - from server
  useEffect(() => {
    if (loginFormSubmitError) {
      setEmailError({ error: true, message: [loginFormSubmitError] })
      setPasswordError({ error: true, message: [loginFormSubmitError] })
    }
    setIsSubmitting(false)
  }, [loginFormSubmitError])

  // NEW PASSWORD ERROR - from server
  useEffect(() => {
    if (newPasswordSubmitError) {
      setNewPasswordError({ error: true, message: [newPasswordSubmitError] })
    }
    setIsSubmitting(false)
  }, [newPasswordSubmitError])

  // NEW TOTP ERROR - from server
  useEffect(() => {
    if (totpError) {
      setNewTotpError({ error: true, message: [totpError] })
    }
    setIsSubmitting(false)
  }, [totpError])

  // NEW PASSWORD CHANGES - show the form field
  useEffect(() => {
    if (passwordChange) {
      setIsNewPassword(true)
    } else {
      setIsNewPassword(false)
    }
    setIsSubmitting(false)
  }, [passwordChange])

  // TOTP CHANGES - show the form field
  useEffect(() => {
    if (totp) {
      setIsTotp(true)
    } else {
      setIsTotp(false)
    }
    setIsSubmitting(false)
  }, [totp])

  return (
    <form onSubmit={handleLoginSubmit}>
      <div className="flex flex-col gap-4">
        <TextField
          id="email"
          name="email"
          label="Email"
          type="email"
          value={email}
          fullWidth
          error={emailError.error}
          helperText={<FormErrorMessage messages={emailError.message} />}
          disabled={isSubmitting || isNewPassword || isTotp}
          onChange={(e) => {
            const value = e.target.value
            debouncedIdentityProviderLookup(value)
            setEmail(value)
          }}
          onBlur={(e) => {
            const value = e.target.value
            debouncedIdentityProviderLookup(value)
          }}
        />
        <TextField
          id="password"
          name="password"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => {
            const value = e.target.value
            setPassword(value)
          }}
          fullWidth
          error={passwordError.error}
          helperText={<FormErrorMessage messages={passwordError.message} />}
          disabled={isSubmitting || isNewPassword || isTotp}
        />

        {isNewPassword && (
          <div>
            <p className="py-6 pb-4 text-center font-bold">
              You need to set a new Password
            </p>
            <NewPasswordField
              isSubmitting={isSubmitting}
              errorState={newPasswordError}
              setErrorState={setNewPasswordError}
              value={newPassword}
              onChange={(value) => {
                setNewPassword(value)
                resetAuthClient()
              }}
            />
          </div>
        )}

        {totp && (
          <TOTP
            value={totpValue}
            errorState={newTotpError}
            onChange={(value) => {
              setTotpValue(value)
              resetAuthClient()
            }}
          />
        )}

        <Button
          loading={isSubmitting}
          type="submit"
          buttonDataTestId="login-button"
          onClick={() => {
            trackEvent('Pages', 'click sign in')
          }}
        >
          {isTotp ? 'Continue' : ''}
          {isNewPassword ? 'Set new password' : ''}
          {!isTotp && !isNewPassword ? 'Sign In' : ''}
        </Button>
        {!isNewPassword && (
          <Button
            disabled={isSubmitting}
            variant="text"
            onClick={() => {
              navigate(routes.passwordResetRequest())
              trackEvent('Pages', 'click forgot password')
            }}
          >
            Forgot your password?
          </Button>
        )}
      </div>
    </form>
  )
}

export default LoginForm
