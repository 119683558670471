import { isEmailValid } from 'api/src/common/utils'

export interface ValidatedInput {
  valid: boolean
  message: string[]
}

export interface NewPasswordValidation {
  minimumLength: boolean
  includesLowercaseChar: boolean
  includesUppercaseChar: boolean
  includesSpecialChar: boolean
  includesNumber: boolean
}

const hasSpecialCharacter = (password: string): boolean => {
  const stringArr = password.split('')

  const specialChars = [
    '^',
    '$',
    '*',
    '.',
    '[',
    ']',
    '{',
    '}',
    '(',
    ')',
    '?',
    '"',
    '!',
    '@',
    '#',
    '%',
    '&',
    '/',
    '\\',
    ',',
    '>',
    '<',
    // eslint-disable-next-line quotes
    "'",
    ':',
    ';',
    '|',
    '_',
    '~',
    '`',
    '=',
    '+',
    '-',
  ]

  const intersection = specialChars.filter((element) =>
    stringArr.includes(element),
  )

  return intersection?.length > 0
}

export const validateNewPassword = (
  password: string,
): NewPasswordValidation => {
  // Set initial state
  const initialValidationState: NewPasswordValidation = {
    minimumLength: false,
    includesLowercaseChar: false,
    includesUppercaseChar: false,
    includesSpecialChar: false,
    includesNumber: false,
  }

  // Check if the password matches length
  if (password.length >= 8) {
    initialValidationState.minimumLength = true
  }

  // Check if the password contains a number
  if (/\d/.test(password)) {
    initialValidationState.includesNumber = true
  }

  // Check if the password contains a lowercase letter
  if (/[a-z]/.test(password)) {
    initialValidationState.includesLowercaseChar = true
  }

  // Check if the password contains a uppercase letter
  if (/[A-Z]/.test(password)) {
    initialValidationState.includesUppercaseChar = true
  }

  // Check if the password contains a special character
  if (hasSpecialCharacter(password)) {
    initialValidationState.includesSpecialChar = true
  }

  return initialValidationState
}

export const validatePassword = (password: string): ValidatedInput => {
  // Set initial state
  const response = {
    valid: true,
    message: [],
  }

  // Check if the password is not empty
  if (password === '') {
    response.valid = false
    response.message.push('Please enter your password')
  }

  return response
}

export const validateEmailAddress = (email: string): ValidatedInput => {
  // Set initial state
  const response = {
    valid: true,
    message: [],
  }

  // Check if the email is not empty
  if (email === '') {
    response.valid = false
    response.message.push('Please enter your email')
  }

  // Check if the email is a Valid address
  const isValid = isEmailValid(email)

  if (!isValid) {
    response.valid = false
    response.message.push('Please enter a valid email')
  }

  // Return response
  return response
}

export const validateMFAInput = (code: string): ValidatedInput => {
  // Set initial state
  const response = {
    valid: true,
    message: [],
  }

  // Check code is not empty
  if (code === '') {
    response.valid = false
    response.message.push('MFA Code must not be empty')
  }

  // Check if the code is of correct length
  // MFA Provider asks for >= 6 OR <= 6
  if (code?.length !== 6) {
    response.valid = false
    response.message.push('MFA Code must be 6 characters.')
  }

  // Check if the code is only numbers
  if (!/^\d+$/.test(code)) {
    response.valid = false
    response.message.push('MFA Code must only contain numbers 0-9')
  }

  return response
}

export const validateTOTPInput = (code: string): ValidatedInput => {
  // Set initial state
  const response = {
    valid: true,
    message: [],
  }

  // Check if the code is not empty
  if (code === '') {
    response.valid = false
    response.message.push('2FA Code must not be empty')
  }

  // Check if the code is only numbers
  if (!/^\d+$/.test(code)) {
    response.valid = false
    response.message.push('2FA Code must only contain numbers 0-9')
  }

  return response
}
