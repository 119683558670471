import { FC } from 'react'

const FormErrorMessage: FC<{ messages: string[] }> = ({ messages }) => {
  if (messages?.length > 0) {
    return (
      <span>
        {messages.map((message, index) => (
          <span className="block" key={index}>
            {message}
          </span>
        ))}
      </span>
    )
  } else {
    return <></>
  }
}

export default FormErrorMessage
