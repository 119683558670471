import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { LockClosedIcon } from '@heroicons/react/24/outline'
import { Divider } from '@mui/material'
import Alert from '@mui/material/Alert'
import { captureEvent, setUser } from '@sentry/browser'
import {
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js'
import { CUSTOM_HTTP_HEADERS, HUBS_URL } from 'api/src/common/enums'
import { isProduction } from 'api/src/common/environment'
import { getBrisbaneTime, isEmailValid } from 'api/src/common/utils'
import axios from 'axios'
import dayjs from 'dayjs'
import debounce from 'lodash.debounce'
import * as OTPAuth from 'otpauth'
import { SSOIdentityProvider } from 'types/graphql'

import { Form } from '@redwoodjs/forms'
import { navigate, routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import { NewPasswordRequired, TotpCallback } from 'src/auth'
import Button from 'src/components/Library/Button/Button'
import Loading from 'src/components/Library/Loading/Loading'
import LoginForm from 'src/components/Login/LoginForm'
import { MFA } from 'src/components/Login/MFA'
import { SSOLoginButton } from 'src/components/Login/SSOLoginButton'
import {
  AuthClient,
  AUTHCLIENTS,
  DefaultAuthClient,
  getLoginUrl,
  getTokenUrl,
} from 'src/lib/authClients'
import { AuthClientContext } from 'src/lib/AuthContext'
import useConfirm from 'src/lib/hooks/Confirmation/useConfirm'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import useLocalStorage from 'src/lib/hooks/UseLocalStorage'
import useRefreshUserAuthDetails from 'src/lib/hooks/useRefreshUserAuthDetails'
import TheHubIcon from 'src/lib/icons/TheHubIcon/TheHubIcon'
import { useAuth } from 'src/Providers'
import { isDebug } from 'src/Util'

export interface SubmitData {
  email?: string
  password?: string
  newPassword?: string
  totpField?: string
}

export interface LoginProps {
  code?: string
  error?: string
  error_description?: string
  mfa?: 'mandatory' | 'optional'
}

const LoginPage: FC<LoginProps> = (props) => {
  const [identityProviderLoading, setIdentityProviderLoading] = useState(false)
  const [auth, setAuth] = useContext(AuthClientContext)
  const { usedAuthClient: authClient } = auth
  const [passwordError, setPasswordError] = useState(null)
  const [loginCodeError, setLoginCodeError] = useState(null)
  const [totpError, setTotpError] = useState(null)
  const [MFAError, setMFAError] = useState(null)
  const { trackEvent } = useAnalytics()

  const { refreshUserAuthDetails } = useRefreshUserAuthDetails()

  const [passwordChange, setPasswordChange] =
    useState<NewPasswordRequired['newPasswordCallback']>()

  const [newPasswordError, setNewPasswordError] = useState<string>(null)
  const [storedEmail, setStoredEmail] = useLocalStorage<string>(
    'storedEmail',
    '',
  )
  const [storedAuthClients, setStoredAuthClients] = useLocalStorage<
    AuthClient[]
  >('storedAuthClients', [DefaultAuthClient])

  const [lastLookupSSOClient, setLastLookupSSOClient] =
    useLocalStorage<AuthClient>('lastLookupSSOClient', null)

  const [failedLoginCount, setFailedLoginCount] = useLocalStorage<number>(
    'failedLoginCount',
    0,
  )

  useEffect(() => {
    // NOT sure why this is here
    if (!lastLookupSSOClient?.email) setLastLookupSSOClient(null)
  }, [lastLookupSSOClient, setLastLookupSSOClient])

  const [ssoError, setSsoError] = useState<string>()
  const [totpCallback, setTOTPCallback] =
    useState<TotpCallback['totpCallback']>()
  const [codeUsed, setCodeUsed] = useState(false)
  const [getTOTP, setTOTP] = useState<OTPAuth.TOTP>()
  const [loggedInUser, setLoggedInUser] = useState<CognitoUser>()
  const [enablingMfa, setEnablingMfa] = useState<boolean>(false)
  const [mfaSet, setMfaSet] = useState(false)
  const [clientMfaSetting, setClientMfaSetting] = useState<
    'OFF' | 'OPTIONAL' | 'MANDATORY'
  >('OPTIONAL')
  const pageUrl = new URL(window.location.href)
  const domain = pageUrl.hostname

  const {
    logIn,
    client,
    loading,
    hasError,
    error,
    isAuthenticated,
    currentUser,
    logOut,
  } = useAuth()

  const clearState = useCallback(() => {
    setTOTP(null)
    setMfaSet(false)
    setLoggedInUser(null)
    setCodeUsed(false)
    setEnablingMfa(false)
    setTOTPCallback(null)
    setMFAError(null)
    setTotpError(null)
    setPasswordError(null)
    setLoginCodeError(null)
  }, [])

  useEffect(() => {
    if (hasError && error) {
      clearState()

      if (error?.message.includes('500')) {
        setLoginCodeError('Please contact your Administrator')
      } else {
        setLoginCodeError(error?.message)
      }
      captureEvent({
        message: 'Login Error',
        level: 'warning',
        extra: { error },
      })
    }
  }, [hasError, error, logOut])

  const setUserMfa = (user: CognitoUser, email: string) => {
    const getTOTPFromSecret = (secret: string) => {
      const totp = new OTPAuth.TOTP({
        issuer: 'The Hub',
        label: email,
        algorithm: 'SHA1',
        digits: 6,
        period: 30,
        secret,
      })

      return totp
    }

    // Promisify the associateSoftwareToken method
    return new Promise<void>((resolve, reject) => {
      user.associateSoftwareToken({
        associateSecretCode: (secret: string) => {
          const totp = getTOTPFromSecret(secret)
          setTOTP(totp)
          resolve()
        },
        onFailure: (err: Error) => {
          captureEvent({
            message: 'Login Error: associateSoftwareToken failed',
            level: 'warning',
            extra: { err },
          })
          reject(err)
        },
      })
    })
  }

  useEffect(() => {
    if (currentUser) {
      const mfaSetting = currentUser?.parentData?.mfaSetting
      if (mfaSetting) {
        setClientMfaSetting(mfaSetting)
      }
    }
  }, [currentUser])

  useEffect(() => {
    if (props.mfa === 'mandatory') {
      client.currentUser().then((user) => {
        if (user) {
          user.getSession((err: Error, session: CognitoUserSession) => {
            if (err) {
              captureEvent({
                message: 'Login Error: getSession error',
                level: 'warning',
                extra: { err },
              })
              return
            } else {
              if (session.isValid()) {
                user.getUserData(
                  async (err, data) => {
                    if (err) {
                      captureEvent({
                        message: 'Login Error: getUserData error',
                        level: 'warning',
                        extra: { err },
                      })
                    } else {
                      if (data.PreferredMfaSetting !== 'SOFTWARE_TOKEN_MFA') {
                        const decodePayload = session
                          .getIdToken()
                          .decodePayload()
                        const email = decodePayload.email
                        setLoggedInUser(user)
                        setEnablingMfa(true)
                        await setUserMfa(user, email)
                        setEnablingMfa(false)
                      } else {
                        navigate(routes.home())
                      }
                    }
                  },
                  {
                    bypassCache: true,
                  },
                )
              }
            }
          })
        }
      })
    }
  }, [client, props])

  useEffect(() => {
    if (
      isAuthenticated &&
      (!enablingMfa || clientMfaSetting === 'OFF') &&
      props.mfa !== 'mandatory'
    ) {
      navigate(routes.home())
    }
  }, [isAuthenticated, enablingMfa, props.mfa, clientMfaSetting])

  useEffect(() => {
    if (hasError || error) {
      setSsoError(error.message)
    }
  }, [hasError, error])

  const resetAuthClient = useCallback(() => {
    if (authClient?.id !== AUTHCLIENTS.DEFAULT) {
      setAuth(DefaultAuthClient)
    }
  }, [authClient?.id, setAuth])

  useEffect(() => {
    if (props.error && props.error_description) {
      const errDesc = props.error_description
      if (
        errDesc.includes('SAML response') &&
        errDesc.includes('PreSignUp failed with error UserNotFoundException')
      ) {
        setLoginCodeError(
          'Error logging in - Please contact your Administrator',
        )
      } else {
        setLoginCodeError(props.error + ':' + props.error_description)
      }
    }
  }, [props.error_description, props.error])

  useEffect(() => {
    if (props.code && lastLookupSSOClient && !loading && !codeUsed) {
      setEnablingMfa(false)
      setCodeUsed(true)
      const bodyFormData = new URLSearchParams()

      bodyFormData.append('client_id', lastLookupSSOClient.clientData.ClientId)
      bodyFormData.append('code', props.code)
      bodyFormData.append('grant_type', 'authorization_code')
      bodyFormData.append(
        'redirect_uri',
        domain === 'localhost'
          ? 'http://localhost:8910/app/login'
          : `https://${domain}/app/login`,
      )

      axios
        .post(getTokenUrl(), bodyFormData)
        .then((result) => {
          const idToken = result.data.id_token
          const accessToken = result.data.access_token
          const refreshToken = result.data.refresh_token

          const session: CognitoUserSession = new CognitoUserSession({
            IdToken: new CognitoIdToken({ IdToken: idToken }),
            AccessToken: new CognitoIdToken({ IdToken: accessToken }),
            ...(refreshToken && {
              RefreshToken: new CognitoRefreshToken({
                RefreshToken: refreshToken,
              }),
            }),
          })

          const decodePayload = session.getIdToken().decodePayload()
          const email = (decodePayload.email as string)?.toLowerCase()

          logIn({
            email,
            session,
          })
            .then((result) => {
              if (result instanceof CognitoUserSession && result?.isValid()) {
                setUser({ email })

                // Fire and forget - Sync Auth details
                refreshUserAuthDetails()

                const existingStoredAuthClient = storedAuthClients.find(
                  (e) => e.email === email && e.id === lastLookupSSOClient.id,
                )

                let storedAuthClient = existingStoredAuthClient
                if (!existingStoredAuthClient) {
                  const timestamp = new Date().toISOString()

                  storedAuthClient = {
                    ...lastLookupSSOClient,
                    email: email.toLowerCase(),
                    lastLogin: timestamp,
                    createdTimestamp: timestamp,
                  }

                  setStoredAuthClients([...storedAuthClients, storedAuthClient])
                } else {
                  setStoredAuthClients(
                    storedAuthClients.map((authClient) =>
                      authClient.email?.toLowerCase() === email
                        ? { ...authClient, lastLogin: new Date().toISOString() }
                        : authClient,
                    ),
                  )
                }

                setStoredEmail(email?.toLowerCase())

                captureEvent({
                  message: 'SSO login: successful login',
                  level: 'info',
                })
                navigate(routes.home())
              }
            })
            .catch((err) => {
              setLoginCodeError(err.message)
              setCodeUsed(false)
            })
        })
        .catch((err) => {
          setCodeUsed(false)
          const status = err.response?.status
          if ([400, 401].includes(status)) {
            setLoginCodeError('Unauthorized. Contact your administrator.')
          } else if ([500, 501, 502, 504].includes(status)) {
            setLoginCodeError(
              `Service unavailable(${err.response.status}). Contact your administrator.`,
            )
          } else {
            setLoginCodeError(err.message)
          }
        })
    }
  }, [
    codeUsed,
    domain,
    loading,
    logIn,
    lastLookupSSOClient,
    props.code,
    setStoredAuthClients,
    setStoredEmail,
    storedAuthClients,
  ])

  const identityProviderLookup = useCallback(
    async (lookUpInput: { email: string; redirect?: boolean }) => {
      if (identityProviderLoading) {
        return null
      }
      // TODO: Add isLoading state to avoid multiple requests

      setIdentityProviderLoading(true)

      try {
        const response = await axios.post(
          // https://docs.redwoodjs.com/docs/environment-variables/#accessing-api-urls
          globalThis.RWJS_API_URL + '/identityProvider',
          lookUpInput,
          {
            headers: {
              [CUSTOM_HTTP_HEADERS.IDENTITY_PROVIDER_API_KEY]:
                process.env.API_ROUTE_SECRET_WEB,
              'content-type': 'application/json',
            },
          },
        )

        const identityProvider: SSOIdentityProvider =
          response?.data?.identityProvider

        if (identityProvider) {
          setStoredEmail(lookUpInput.email?.toLowerCase())
          const timestamp = new Date().toISOString()
          const newAuthClient: AuthClient = {
            id: identityProvider.clientName,
            clientData: {
              UserPoolId: process.env.COGNITO_POOL_ID,
              ClientId: identityProvider.clientId,
            },
            identityProvider: identityProvider.providerName,
            email: lookUpInput.email?.toLowerCase(),
            createdTimestamp: timestamp,
          }

          setFailedLoginCount(0)
          setLastLookupSSOClient(newAuthClient)

          if (lookUpInput.redirect) {
            setAuth(newAuthClient)
            setTimeout(() => {
              const loginUrl = getLoginUrl({
                identityProvider: newAuthClient.identityProvider,
                clientId: newAuthClient.clientData.ClientId,
              })
              window.location.href = loginUrl
            }, 250)
          }

          return newAuthClient
        }
        setFailedLoginCount(0)
      } catch (err) {
        setFailedLoginCount((currentValue) => currentValue + 1)
        const errMessage =
          err.message === 'Request failed with status code 500'
            ? err.response?.data?.message
            : err.message
        if (isDebug) {
          setLoginCodeError('Identity Provider Lookup Error: ' + errMessage)
        } else {
          setLoginCodeError(
            'Error looking up identity provider. Please contact your administrator',
          )
        }
      } finally {
        setIdentityProviderLoading(false)
      }
    },
    [identityProviderLoading, setStoredEmail, setLastLookupSSOClient, setAuth],
  )

  useEffect(() => {
    if (storedEmail && !lastLookupSSOClient) {
      identityProviderLookup({ email: storedEmail })
    }
  }, [])

  useEffect(() => {
    if (failedLoginCount < 2) return
    // if the user is struggling to login in, open up an intercom chat
    // we want users submitting issues through intercom and not the website / other channels
    window.Intercom('show')
    window.Intercom('showNewMessage')
  }, [failedLoginCount])

  // TODO: Find out what this is for
  const identityProviderLookupDebounce = useCallback(
    (value: string) => {
      const email: string = value?.toLowerCase()
      if (isEmailValid(email)) {
        const storedAuthClient = storedAuthClients.find(
          (e) => e.email === email && e.lastLogin,
        )
        if (storedAuthClient) {
          if (authClient.id !== storedAuthClient.id) {
            setStoredEmail(email?.toLowerCase())
            setLastLookupSSOClient(storedAuthClient)
          }
        } else {
          identityProviderLookup({ email })
        }
      }
    },
    [
      authClient.id,
      identityProviderLookup,
      setLastLookupSSOClient,
      setStoredEmail,
      storedAuthClients,
    ],
  )

  const debouncedIdentityProviderLookup = useMemo(
    () => debounce(identityProviderLookupDebounce, 1000),
    [identityProviderLookupDebounce],
  )

  const redirectPasswordResetRequest = (email: string) => {
    const tID = setTimeout(function () {
      window.clearTimeout(tID)
      navigate(
        routes.passwordResetRequest({ resetEmail: encodeURIComponent(email) }),
      )
    }, 3000)
  }

  const getTimesForLogging = () => {
    return {
      browserLocalTime: dayjs().format(),
      browserTimeUTC: dayjs().utc().format(),
      brisbaneTime: getBrisbaneTime().format(),
      browserTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
  }

  // TODO: This needs to be refactored, and types need to be added.
  const onSubmitPassword = useCallback(
    async (data: SubmitData) => {
      setLoginCodeError(null)
      const {
        email: tmpEmail,
        password,
        newPassword,
        totpField: totpCode,
      } = data

      const email = tmpEmail.toLowerCase()
      // Sentry: Set the user as soon as possible, so we can start logging/tracking progress
      setUser({ email })

      setPasswordError(null)
      setNewPasswordError(null)
      setTotpError(null)

      // This is called when a TOTP code is submitted
      const loginUser = async (session: CognitoUserSession) => {
        await logIn({
          email,
          session,
        })
      }

      let response:
        | Error
        | NewPasswordRequired
        | CognitoUserSession
        | TotpCallback = null
      try {
        if (passwordChange) {
          response = await passwordChange(newPassword)
        } else if (totpCallback) {
          response = await totpCallback(totpCode)
        } else {
          setEnablingMfa(true)

          // !IMPORTANT This is the initial login step with username and password
          response = await logIn({
            email,
            password,
          })

          if (isProduction) {
            const currentUrl = new URL(window.location.href)
            const prodUrl = new URL(HUBS_URL.PRODUCTION)
            const currentURLHostname = currentUrl.hostname.toLowerCase()
            const prodURLHostname = prodUrl.hostname.toLowerCase()

            if (currentURLHostname !== prodURLHostname) {
              const emailIsStafflink = email
                .toLowerCase()
                .includes('@stafflink.com.au')

              captureEvent({
                message: `PROD SLOT Usage: ${email} - ${currentURLHostname}`,
                level: emailIsStafflink ? 'info' : 'warning',
                extra: {
                  currentURLHostname,
                  prodURLHostname,
                },
              })
            }
          }

          if (response instanceof Error) {
            throw response
          }

          // Successful username/password: MFA step not done (if applicable)
          const existingAuthClient = storedAuthClients.find(
            (e) => e.email === email,
          )

          let storedAuthClient = existingAuthClient
          if (!existingAuthClient) {
            const timestamp = new Date().toISOString()
            storedAuthClient = {
              ...authClient,
              email,
              lastLogin: timestamp,
              createdTimestamp: timestamp,
            }
            setStoredAuthClients([...storedAuthClients, storedAuthClient])
          } else {
            setStoredAuthClients(
              storedAuthClients.map((authClient) =>
                authClient.email === email
                  ? { ...authClient, lastLogin: new Date().toISOString() }
                  : authClient,
              ),
            )
          }
          setStoredEmail(email?.toLowerCase())

          setPasswordChange(
            () => (response as NewPasswordRequired).newPasswordCallback,
          )
          setTOTPCallback(() => (response as TotpCallback).totpCallback)

          if ((response as TotpCallback).totpCallback) {
            captureEvent({ message: 'logIn: TOTP required', level: 'info' })
            setMfaSet(true)
            setEnablingMfa(false)
          }
        }

        // @ts-expect-error: Not sure what type this is supposed to be
        if (response?.accessToken) {
          // User has successfully logged in with Username, password and MFA (if applicable)
          captureEvent({ message: 'logIn: successful login', level: 'info' })
          const user = await client.currentUser()
          setLoggedInUser(user)

          // Fire and forget - Sync Auth details
          refreshUserAuthDetails()

          if (!mfaSet && clientMfaSetting !== 'OFF') {
            // Display the option to setup MFA or skip
            await setUserMfa(user, email)
          } else {
            // This is the final step in the login process with TOTP
            setEnablingMfa(false)
            // The TOPT code is submitted with the the previous successful step (user/pass creds login)
            loginUser(response as CognitoUserSession)
          }
          setFailedLoginCount(0)
        }
      } catch (error) {
        setFailedLoginCount((currentValue) => currentValue + 1)
        if (error.message === 'Password reset required for the user') {
          captureEvent({
            message: 'logIn: password reset required',
            level: 'info',
          })
          setPasswordError(
            'Password reset required. Redirecting to reset page now...',
          )
          redirectPasswordResetRequest(email)
          return
        }

        if (
          // NOTE: this condition is a bit unknown - we'll just trust it for now.
          // TODO: Remove all legacy cognito code,and migrate legacy users.
          error.message.includes(
            'UserMigration failed with error User does not exist',
          )
        ) {
          captureEvent({
            message: 'logIn: Legacy password error - let RJ know',
            level: 'error',
          })
          setPasswordError('Incorrect username or password.')
          return
        }

        if (passwordChange) {
          captureEvent({
            message: 'logIn: password change error',
            level: 'warning',
            extra: { error },
          })
          setNewPasswordError(error.message)
          return
        }

        if (totpCallback) {
          captureEvent({
            message: 'logIn: TOTP error',
            level: 'warning',
            extra: { error },
          })
          setTotpError(error.message)
          return
        }

        // TODO: This should be wrapped in an IF statement,
        // and all other errors should be logged as an error, as it's an unknown state
        captureEvent({
          message: 'logIn: password error',
          level: 'warning',
          extra: { error },
        })
        setPasswordError(error.message)
      }
    },
    [
      passwordChange,
      totpCallback,
      logIn,
      setStoredEmail,
      storedAuthClients,
      setStoredAuthClients,
      authClient,
      client,
      mfaSet,
    ],
  )

  const confirm = useConfirm()

  const onSubmitQR = useCallback(
    (totpTokenFromUser: string) => {
      setMFAError(null)

      const totpTokenForConfirm = getTOTP.generate()
      const tokensAreTheSame = totpTokenForConfirm === totpTokenFromUser

      loggedInUser.verifySoftwareToken(totpTokenFromUser, 'My TOTP device', {
        onSuccess: (_result) => {
          setTOTP(null)
          trackEvent('MFA', 'MFA verification Success')

          if (!tokensAreTheSame) {
            const previousToken = getTOTP.generate({
              timestamp: dayjs().subtract(1, 'minute').toDate().getTime(),
            })

            if (previousToken !== totpTokenFromUser) {
              // Very Weird case - Should never happen - but we need to know about it
              captureEvent({
                message: 'MFA verification Success - tokens are NOT the same',
                level: 'warning',
                extra: {
                  ...getTimesForLogging(),
                },
              })
            }
          }

          // Set Cognito user MFA preference
          loggedInUser.setUserMfaPreference(
            // SMS settings
            { PreferredMfa: false, Enabled: false },
            // Software MFA settings
            { PreferredMfa: true, Enabled: true },
            async (err, _result) => {
              // Error or success, best to refresh the user's auth details
              refreshUserAuthDetails()

              if (err) {
                trackEvent('MFA', 'MFA setUserMfaPreference Error')
                captureEvent({
                  message: 'Login Error: setUserMfaPreference error',
                  level: 'warning',
                  extra: { err },
                })
                setLoginCodeError(err.message)
                setMFAError(err.message)
              } else {
                trackEvent('MFA', 'MFA setUserMfaPreference Success')
                captureEvent({
                  message: 'setUserMfaPreference Success',
                  level: 'info',
                })
                // At this point, the user has successfully enabled MFA and is now logged in
                await confirm({
                  title: 'Your MFA has been successfully activated!',
                  confirmationText: 'Continue',
                  hideCancelButton: true,
                })

                setEnablingMfa(false)
                setMfaSet(true)
                navigate(routes.home())
              }
            },
          )
          setFailedLoginCount(0)
        },
        onFailure: (err) => {
          setFailedLoginCount((currentValue) => currentValue + 1)

          // See: https://repost.aws/knowledge-center/cognito-mfa-errors for more info
          const invalidSessionError = [err?.name, err?.['code']].includes(
            'NotAuthorizedException',
          )

          const message = (() => {
            if (invalidSessionError) {
              // Either the TOPT challenge session has expired (3 minutes)
              // or the Session has already been used
              return (
                'Session has expired.\n' +
                'Delete the "The Hub" account on your authenticator App.\n' +
                'Refresh the page and try again.'
              )
            }

            return tokensAreTheSame
              ? 'Please contact Support for assistance.'
              : err.message
          })()

          setLoginCodeError(message)
          setMFAError(message)

          if (tokensAreTheSame && !invalidSessionError) {
            // This is a weird case - should never happen, but we need to know about it
            trackEvent('MFA', 'MFA verification failure - tokens are the same')
            captureEvent({
              message: 'MFA verification failure - tokens are the same',
              level: 'error',
              extra: {
                err,
                ...getTimesForLogging(),
              },
            })
          } else {
            trackEvent('MFA', 'MFA verification failure')
            captureEvent({
              message: 'MFA verification failure',
              level: 'warning',
              extra: {
                invalidSessionError,
                err,
                // Adding error(Name|Code) as it gets scrubbed from the error in Sentry
                errorName: err?.name,
                errorCode: err?.['code'],
                ...getTimesForLogging(),
              },
            })
          }
        },
      })
    },
    [confirm, getTOTP, loggedInUser],
  )

  const showSection = (() => {
    if (props.code && !loginCodeError) {
      return 'loading-sso'
    }
    if (getTOTP) {
      return 'mfa'
    }
    return 'login'
  })()

  const showLoginSection = (() => {
    if (showSection !== 'login') {
      return null
    }
    if (authClient?.id === AUTHCLIENTS.DEFAULT) {
      return 'password'
    }
    return 'sso'
  })()

  const handleLoginWithMicrosoft = async () => {
    clearState()

    if (
      !lastLookupSSOClient ||
      lastLookupSSOClient.id === AUTHCLIENTS.DEFAULT
    ) {
      const ssoAuthClient = await identityProviderLookup({
        email: storedEmail,
      })

      if (ssoAuthClient) {
        setAuth(ssoAuthClient)
      }
    } else {
      setAuth(lastLookupSSOClient)
    }
  }

  return (
    <>
      <Metadata title="Sign In" description="Sign In to The Hub" />
      <div className="relative bg-white from-white to-gray-200 sm:bg-gradient-to-r">
        <div className="relative flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
          {!isProduction && (
            <div className="mb-6 flex w-full justify-center p-2">
              <div className="w-48 rounded-lg bg-red-500 p-1 text-center">
                <p className="text-xl font-bold text-white">
                  {process.env.ENVIRONMENT}
                </p>
              </div>
            </div>
          )}

          <div className="flex flex-col flex-wrap overflow-hidden rounded-lg bg-white sm:mx-auto sm:w-3/4 sm:shadow-xl lg:max-w-5xl lg:flex-row">
            <div className="hidden w-full grow flex-col justify-center gap-10 rounded-s-lg bg-red-100 bg-gradient-to-r from-sky-500 to-blue-600 p-10 lg:flex lg:w-1/2">
              <img
                src="/the-hub-page-illustration.png"
                alt=""
                className="hidden w-full pt-20 sm:inline"
                width="100"
                height="auto"
              />
              <div className="pb-10 text-center">
                <div className="mx-auto inline-block">
                  <TheHubIcon variant="white" size="small" />
                </div>
                <p className="fw-bold bold pt-2 text-[18px] font-bold italic text-white">
                  One Team, One Goal.
                </p>
              </div>
            </div>
            <div className="flex w-full flex-col items-center justify-around bg-white p-10 py-16 lg:w-1/2 xl:p-20">
              {showSection === 'loading-sso' && (
                <div className="flex w-full justify-center">
                  <Loading />
                </div>
              )}
              {showSection === 'mfa' && (
                <div className="flex w-full justify-center">
                  <MFA
                    getTOTP={getTOTP}
                    onSubmitQR={onSubmitQR}
                    MFAError={MFAError}
                    mandatory={
                      props.mfa === 'mandatory' ||
                      clientMfaSetting === 'MANDATORY'
                    }
                    setFailedLoginCount={setFailedLoginCount}
                  />
                </div>
              )}
              {showSection === 'login' && (
                <>
                  {showLoginSection === 'password' && (
                    <>
                      <div className="w-full text-center">
                        <div className="mx-auto mb-6 inline-flex">
                          <TheHubIcon variant="default" size="small" />
                        </div>

                        <h2 className="mb-6 text-center text-xl font-bold">
                          Sign in to your account
                        </h2>

                        <Form className="mb-2 mt-2" onSubmit={onSubmitPassword}>
                          <div>
                            {lastLookupSSOClient && (
                              <>
                                <Divider className="word-wrap my-2 text-gray-600">
                                  Your organisation requires Microsoft login
                                </Divider>
                                <Button
                                  variant={'outlined'}
                                  className={
                                    'mt-4 animate-pulse shadow-sm shadow-indigo-500/50'
                                  }
                                  size={'large'}
                                  startIcon={
                                    <LockClosedIcon className="h-5 w-5" />
                                  }
                                  onClick={handleLoginWithMicrosoft}
                                  buttonDataTestId="login-with-microsoft-button"
                                >
                                  <p className={'mt-1'}>Login with Microsoft</p>
                                </Button>
                                <Divider className="py-8 text-gray-600">
                                  OR
                                </Divider>
                              </>
                            )}
                          </div>
                        </Form>

                        <LoginForm
                          loginToTheHub={onSubmitPassword}
                          loginFormSubmitError={passwordError}
                          newPasswordSubmitError={newPasswordError}
                          totpError={totpError}
                          defaultEmail={storedEmail}
                          passwordChange={passwordChange}
                          resetAuthClient={resetAuthClient}
                          debouncedIdentityProviderLookup={
                            debouncedIdentityProviderLookup
                          }
                          totp={totpCallback}
                        />
                        {loginCodeError && (
                          <Alert severity="error" className="mt-4">
                            An error occurred: {loginCodeError}
                          </Alert>
                        )}
                      </div>
                    </>
                  )}
                  {showLoginSection === 'sso' && (
                    <div className="flex w-full flex-col gap-4">
                      <div className="mx-auto mb-2 inline-flex">
                        <TheHubIcon variant="default" size="small" />
                      </div>

                      <h2 className="mb-0 text-center text-xl font-bold">
                        Sign in to your account
                      </h2>
                      {ssoError && (
                        <Alert severity="error" className="mt-4">
                          An error occurred with SSO login - please contact your
                          administrator
                        </Alert>
                      )}
                      <SSOLoginButton
                        identityProviderLoading={identityProviderLoading}
                        identityProviderLookup={identityProviderLookup}
                        storedAuthClients={storedAuthClients}
                        setStoredAuthClients={setStoredAuthClients}
                      />
                      <div>
                        <Divider className="mt-2 text-gray-600">OR</Divider>
                      </div>
                      <Button
                        onClick={() => setAuth(DefaultAuthClient)}
                        variant="text"
                      >
                        Login with your email address
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage
